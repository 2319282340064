const no = {
  locale: {
    language: 'Språk',
    no: 'Norsk',
    en: 'Engelsk',
  },
  loading: 'Laster',
  open: 'Åpne',
  close: 'Lukk',
  yes: 'Ja',
  no: 'Nei',
  lastUpdated: 'Sist oppdatert',
  ago: 'siden',
  noResults: 'Ingen treff',

  home: {
    unauthorized: {
      welcome: 'Velkommen til Yoga 2.0',
      firstTime: 'Første gang du logger inn?',
      intro:
        '1. Logg inn med BankID\n2. Be support / service avdelingen om rettigheter\n3. Logg inn på nytt',
    },
    authorized: {
      welcome: 'Hei ${name}',
      goToSettings: 'Konfigurer innstillinger',
    },
  },
  select: {
    empty: 'Ingen valgmuligheter',
    unselected: 'Velg et alternativ…',
  },

  nav: {
    dashboard: 'Hjem',
    consultations: 'Konsultasjoner',
    allConsultations: 'Alle konsultasjoner',
    myConsultations: 'Mine konsultasjoner',
    prescriptions: 'Resepter',
    history: 'Historikk',
    settings: 'Innstillinger',
    help: 'Hjelp',
    signOut: 'Logg ut',
    signIn: 'Logg inn',
    search: 'Søk',
    closeSidebar: 'Lukk meny',
    openSidebar: 'Åpne meny',
  },
  auth: {
    notLoggedIn: 'Ikke innlogget',
    signIn: 'Logg inn',
    signOut: 'Logg ut',
    insufficientPermissions: 'Mangler rettigheter til å se denne siden',
  },
  settings: {
    pageTitle: 'Innstillinger',
    configureSettings: 'Konfigurer innstillinger',
    next: 'Neste',
    unsupported: {
      speakerSelection: 'Valg av høytaler er ikke støttet i din nettleser',
    },
    browserRecommendation:
      'Vi anbefaler at du bruker Google Chrome for å oppnå den beste brukeropplevelsen.',
    permissionError: 'Mangler tillatelser. Oppdater nettleserinnstillingene.',
    info: {
      intro:
        'Vi må sjekke at videokamera, mikrofonen og høytaleren din fungerer før du kan ha videosamtaler.',
      done: 'Ferdig',
    },
    deviceKinds: {
      camera: 'Kamera',
      mic: 'Mikrofon',
      speaker: 'Høytaler',
    },
    steps: {
      camera: {
        title: 'Velg Kamera',
        preview: 'Forhåndsvisning',
        unavailable: 'Ingen kamera valgt/tilgjengelig',
      },
      mic: {
        title: 'Velg Mikrofon',
        validated: 'Snakk for å teste mikrofonen',
        awaitingValidation: 'Mikrofonen fungerer som den skal',
        unavailable: 'Ingen mikrofon valgt/tilgjengelig',
      },
      speaker: {
        title: 'Velg Høyttaler',
        unavailable: 'Ingen høyttaler valgt/tilgjengelig',
        demoSound: 'Spill av lydklipp',
      },
    },
  },
  error: {
    header: 'Noe gikk galt:',
    retry: 'Prøv igjen',
    camera: 'En feil har oppstått. Kunne ikke hente kamera.',
  },
  consultation: {
    notCompleted: 'Ikke utført',
    emptyList: 'Ingen konsultasjoner i kø',
    emptyListHistory: 'Ingen fullførte konsultasjoner',
    reclaimConfirm: 'Er du sikker på du vil overta denne konsulasjonen?',
    mediaWarning:
      'Konfigurer kamera og lydenheter riktig før du starter en konsultasjon.',
    changeSettings: 'Endre innstillinger',
    notCompletedWarning:
      'Du har videotimer som ikke er fullført. Vennligst fullfør eller tilbakebetal via ',

    receipt: {
      summary: 'Oppsummering',
      doctor: 'Behandler',
      service: 'Tjeneste',
      status: 'Status',
      patient: 'Pasient',
      partner: 'Partner',
      ssn: 'Fødselsnummer',
      refundedAt: 'Refundert',
      duration: 'Lengde',
      sum: 'Sum',
      hours: 'Timer',
      minutes: 'Minutter',
      NOK: 'kr',
      consultationStatus: {
        initiated: 'Initialisert',
        booked: 'Bestilt',
        payed: 'Betalt',
        'no-show': 'No-show',
        serving: 'Under behandling',
        claimed: 'Under behandling',
        refunded: 'Refundert',
        completed: 'Fullført',
        cancelled: 'Kansellert',
      },
    },
    status: {
      unclaimed: 'Tilgjengelig pasient',
      claimed: '${professionalName}',
      waited: 'Ventet ${minutes} minutter',
      serving: 'Må fullføres',
      completed: 'Fullført ${time}',
      refunded: 'Refundert ${time}',
      cancelled: 'Kansellert ${time}',
    },
    modal: {
      reason: 'Årsak',
      phone: 'Telefonnummer',
      goto: 'Åpne konsultasjon',
      claim: 'Ta konsultasjon',
      'claim-other': 'Overta konsultasjon',
      unclaim: 'Slipp konsultasjon',
      appointmentId: 'Appointment id',
    },
    closeModal: {
      back: 'Tilbake',
      title: 'Forlat avtale?',
      message: 'Er du sikker på du vil forlate denne avtalen?',
    },
    sidebar: {
      time: 'Tid',
      reason: 'Årsak',
      phone: 'Telefonnummer',
      age: 'Alder',
      gender: 'Biologisk kjønn',
      male: 'Mann',
      female: 'Kvinne',
      ssn: 'Fødselsnummer',
      appointmentId: 'Appointment id',
    },
    video: {
      states: {
        joiningRoom: 'Videorom gjøres klart',
        sendingNotification: 'Varsling sendes til pasient app',
        initiatingCall: 'Kobler til pasient app',
        calling: 'Pasienten ringes',
      },
      counters: {
        initiatingCall: 'Venter',
        calling: 'Venter på svar',
      },
      actions: {
        joinRoom: 'Åpne video-rom',
        call: 'Ring opp',
        callAgain: 'Ring igjen',
        cancelCall: 'Avbryt',
        endConsultation: 'Legg på',
        exit: 'Avslutt',
        complete: 'Fullfør',
        completed: 'Fullført',
      },
      buttons: {
        toggleMic: 'Mikrofon',
        toggleCam: 'Kamera',
      },
      callResults: {
        ON_DECLINE: 'Pasient avviste',
        NOT_ANSWERED: 'Pasient svarte ikke',
        TIMEOUT: 'Anrop utløpt',
      },

      connecting: 'Kobler til',
      connected: 'Koblet til',
      room: 'Koblet til videorom',
    },
    refund: {
      refunding: 'Tilbakebetaling',
      refunded: 'Tilbakebetalt',
      refund: 'Tilbakebetal',
      cause: 'Grunnlag',
      reason: 'Årsak',
      reasonTitle: 'Legg til kommentar',
      reasonPlaceholder: 'Hjelp oss til å forstå årsaken',
      cancel: 'Avbryt',
      causes: {
        technicalError: 'Teknisk feil',
        noAnswer: 'Ubesvart anrop',
        other: 'Annet',
      },
    },
  },
  user: {
    missingName: '[Mangler navn]',
    missingPhone: '[Mangler telefon]',
  },

  prescription: {
    emptyList: 'Ingen resepter i kø',
    emptyListHistory: 'Ingen fullførte resepter',
    copyText: 'Kopier all tekst',
    prescription: 'Resept',

    claimedByOther: {
      title: 'Denne resepten er allerede tatt av en annen behandler.',
      back: 'Tilbake til reseptlisten',
    },

    categories: {
      experiences: 'Tidligere erfaringer',
      guide: 'Guide',
      health: 'Helse',
      details: 'Detaljer',
      other: 'Annet',
    },

    claimModal: {
      info: 'Det er viktig at du behandler bestillingen etter som andre leger ikke vil ha tilgang til resepten.',
      claim: 'Start behandling',
      cancel: 'Avbryt',
      goto: 'Åpne resept',
      cancelTreatment: 'Avbryt behandling',
    },

    list: {
      claimed: 'Mine resepter — under arbeid',
    },

    accept: 'Godkjenn',
    reject: 'Avvis',
    cancel: 'Avbryt behandling',

    acceptModal: {
      title: 'Vil du godkjenne denne resepten?',
      message:
        'Husk å utstede e-resept og føre inn i Pasientsky før du godkjenner.',
      success: 'Lukk',
    },

    declineModal: {
      title: 'Vil du avvise denne resepten?',
      message: 'Du kan ikke gå tilbake og endre valget senere.',
    },

    uploadModal: {
      title:
        'Vil du godkjenne denne resepten ved å laste opp det relaterte dokumentet?',
      message:
        'For å godkjenne bestllingen må du lage et relatert dokument i Fürst, laste det ned for så å laste det opp her',
      uploadFile: 'Last opp fil',
      fileUploaded: 'Fil ble suksessfult lastet opp',
      wrongFile:
        'Informasjonen i filen stemmer ikke med pasientents informasjon. Prøv igjen med en annen fil',
    },

    columns: {
      patient: 'Pasient',
      phone: 'Telefonnummer',
      ssn: 'Personnummer',
    },

    badge: {
      claimed: 'Blir behandlet',
    },

    details: 'Detaljer',
    products: 'Produkter',
    questions: 'Spørsmål',
    choices: 'Valg',

    age: 'Alder',
    bmi: 'BMI',
    unknown: 'Ukjent',

    product: {
      name: 'Produktnavn',
      category: 'Kategori',
      duration: 'Varighet',
    },
  },
}

export type LangDefinition = typeof no
export default no
